import { useState, useEffect } from 'react';
import facilityService from '../../services/facility_profile';

export const useGetFacilities = () => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    (async () => {
      const listFacilities = await facilityService.fetchListFacilities();
      setFacilities(listFacilities);
    })();
  }, []);

  return { facilities };
};

export default useGetFacilities;