import apiClient from "../utils/apiClient";

const portfolioService = {
  async getEcriSettings(portfolioId) {
    try {
      const response = await apiClient.get(`/portfolio/${portfolioId}`);
      return response;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    return {};
  },
  async saveEcriSettings(portfolioId, ecriSettings) {
    try {
      await apiClient.put(
        `/portfolio/ecri/${portfolioId}`,
        { ecri_settings: ecriSettings }
      );
      alert("Settings saved successfully");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  },
};

export default portfolioService;
