import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../components/Dashboard'
import SearchInput from '../components/atom/SearchInput'
import Select from '../components/atom/Select'
import SelectGroup from '../components/atom/SelectGroup'
import CompetitorTypeSelect from '../components/CompetitorTypeSelect'
import Map from '../components/atom/Map'
import axios from 'axios'
import { STRATEGY_OPTIONS } from '../utils/config'
import { AuthContext } from '../context/authContext'
import { geocodeAddress } from '../utils/map'

const strategyOptions = STRATEGY_OPTIONS.map((item) => item.label)

async function fetchListCompetitorApi({ token, search, storeTrackId }) {
  try {
    let api = `/api/competitor/${storeTrackId}${search ? `?search=${search}` : ''}`
    const response = await fetch(api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const { result } = await response.json()
    return result || []
  } catch (error) {
    console.error('Error fetching data: ', error)
  }
  return []
}

async function fetchListFacilities({ token, customerId }) {
  try {
    const response = await fetch(`/api/street_rates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const { result } = await response.json()
    return result || []
  } catch (error) {
    console.error('Error fetching data: ', error)
  }
  return []
}

async function fetchUpdateFacility({ token, facilityId, updateParams }) {
  try {
    const response = await fetch(`/api/facility_profile/facility/${facilityId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateParams),
    })
    const { result } = await response.json()
    return result || []
  } catch (error) {
    console.error('Error fetching data: ', error)
  }
  return []
}

const Competitors = () => {
  const { id } = useParams()
  const { auth } = useContext(AuthContext);
  const customerId = auth?.user?.id
  const [search, setSearch] = useState('')
  const [debouncedTerm, setDebouncedTerm] = useState(search)
  const [strategy, setStrategy] = useState()
  const [facilities, setFacilities] = useState([])
  const [competitors, setCompetitors] = useState([])
  const [facility, setFacility] = useState()
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 })
  const [fetching, setFetching] = useState(false)
  const [facilityCoords, setFacilityCoords] = useState(null)

  const facilityOptions = facilities.map((item) => item.facility_name)

  const updateCompetitor = async (competitorId, updateParams) => {
    const token = localStorage.getItem('token')
    const headers = { Authorization: `Bearer ${token}`, }
    await axios.put(`/api/competitor/${competitorId}`, updateParams, { headers });
    const storeTrackId = facility.stortrack_id;
    setFetching(true)
    fetchListCompetitorApi({ token, search, storeTrackId }).then((listCompetitors) => {
      setCompetitors(listCompetitors)
    }).finally(() => {
      setFetching(false)
    })
  }

  const handleChange = (e) => {
    const value = e.target.value
    setSearch(value)
  }

  const handleChangeFacility = async (selected) => {
    const selectedFacility = facilities.find((item) => item.facility_name === selected)
    if (!selectedFacility) return;
    const token = localStorage.getItem('token');
    const storeTrackId = selectedFacility.stortrack_id;
    setFacility(selectedFacility)
    const facilityCoordinate = await geocodeAddress(selectedFacility.address, selectedFacility.market)
    setFacilityCoords(facilityCoordinate)
    const strategyLabel = STRATEGY_OPTIONS.find((item) => item.value === selectedFacility.street_rate_strategy)?.label
    setStrategy(strategyLabel)
    setFetching(true)
    fetchListCompetitorApi({ token, search, storeTrackId }).then((listCompetitors) => {
      setCompetitors(listCompetitors)
    }).finally(() => {
      setFetching(false)
    })
  }

  const handleChangeCompetitorType = (competitorId, type) => {
    const competitorIndex = competitors.findIndex(item => item.id === competitorId);
    if (competitorIndex !== -1) {
      updateCompetitor(competitorId, { comp_type: type })
    }
    //
  }

  const handleStrategySelected = (selected) => {
    setStrategy(selected)
    const token = localStorage.getItem('token')
    const strategyValue = STRATEGY_OPTIONS.find((item) => item.label === selected)?.value
    fetchUpdateFacility({ token, facilityId: facility.facility_id, updateParams: { street_rate_strategy: strategyValue } })
  }

  const handleHoverEvent = (competitor) => {
    setMapCenter({ lat: +competitor.latitude, lng: +competitor.longitude })
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [search])

  useEffect(() => {
    if (!facility) return;
    const token = localStorage.getItem("token");
    const storeTrackId = facility.stortrack_id;
    setFetching(true);
    fetchListCompetitorApi({ token, search: debouncedTerm, storeTrackId })
      .then((listCompetitors) => {
        setCompetitors(listCompetitors);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [debouncedTerm, facility]);

  useEffect(() => {
    (async () => {
      if (!customerId) return
      const token = localStorage.getItem('token')
      const listFacilities = await fetchListFacilities({ token, customerId: customerId })
      setFacilities(listFacilities)
      if (listFacilities.length) {
        const facility = listFacilities.find((item) => item.id === parseInt(id))
        setFacility(facility || listFacilities[0])
        const facilityCoordinate = await geocodeAddress(listFacilities[0].address, listFacilities[0].market)
        setFacilityCoords(facilityCoordinate)
        const strategyLabel = STRATEGY_OPTIONS.find((item) => item.value === listFacilities[0].street_rate_strategy)?.label
        setStrategy(strategyLabel)
        const storeTrackId = listFacilities[0].stortrack_id;
        setFetching(true)
        const listCompetitors = await fetchListCompetitorApi({ token, search: '', storeTrackId });
        setFetching(false)
        setCompetitors(listCompetitors)
      }
    })()
  }, [customerId, id])

  useEffect(() => {
    if (facilityCoords) {
      setMapCenter(facilityCoords)
    } else {
      setMapCenter({ lat: 37.7749, lng: -122.4194 })
    }
  }, [facilityCoords])

   const competitorLocations = competitors.map((comp) => ({
     position: {
       lat: +comp.latitude,
       lng: +comp.longitude,
     },
     title: comp.store_name,
   }));

   const facilityLocation = facilityCoords
     ? {
         position: facilityCoords,
         title: facility?.facility_name,
       }
     : null;

  const tableHeader = () => {
    return (
      <>
        <div className="px-10 pb-6 border-b border-slate-300 flex justify-between">
          <Select selected={facility?.facility_name} options={facilityOptions} onChange={handleChangeFacility} className="w-72" />
          <div className="flex">
            <div className="flex items-center w-36">
              <img src="/assets/images/light-bulb.png" className="h-5" alt="Light Bulb Icon" />
              <h1 className="mx-2">Strategy</h1>
              {/* <CompetitorTypeSelect selected="Non-competitor" /> */}
            </div>
            <SelectGroup
              options={strategyOptions}
              onChange={handleStrategySelected}
              selected={strategy}
            />
          </div>
        </div>
        <div className="mt-4 py-4 px-10 flex justify-between">
          <SearchInput placeholder={'Search...'} value={search} onChange={handleChange} />
          <div>
            <a
              href="/street-rates"
              className="px-8 py-3 rounded rounded-lg bg-green-600 text-white"
            >
              Back to Street Rates
            </a>
          </div>
        </div>
      </>
    )
  }

  const facilitiesTable = () => {
    return (
      <table id="competitors-table" className="w-full">
        <thead>
          <tr className="uppercase text-left text-sm text-gray-600">
            <th className="w-6 p-2"></th>
            <th className="p-2">Competitor</th>
            <th className="p-2">
              Distance
              <br />
              (Miles)
            </th>
            <th className="p-2">
              Competitor
              <br />
              type
            </th>
          </tr>
        </thead>
        <tbody>
          {competitors.map((competitor) => (
            <tr key={competitor.id} onMouseEnter={() => handleHoverEvent(competitor)}>
              <td className="p-2">
                {competitor.comp_type ? (
                  ''
                ) : (
                  <img
                    src="/assets/images/danger-circle.png"
                    className="w-4 h-4"
                    alt="Warning Icon"
                  />
                )}
              </td>
              <td className="pr-4 p-2">
                <a href={competitor.source_url} className="text-blue-500" target="_blank" rel="noreferrer">
                  {competitor.store_name} {competitor.address}
                </a>
              </td>
              <td className="p-2">{competitor.distance}</td>
              <td className="p-2">
                <CompetitorTypeSelect selected={competitor.comp_type} onChange={value => handleChangeCompetitorType(competitor.id, value)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderTable = () => {
    if (fetching) {
      return (<div className="flex w-full h-4/5 items-center justify-center">Loading ...</div>)
    }
    return (
      <div className="w-full border rounded rounded-2xl border-2 border-gray-200 pt-6 bg-white">
        {tableHeader()}
        <div className="w-full flex px-10 py-4">
          <div className="flex-1 h-[600px] overflow-y-scroll">{facilitiesTable()}</div>
          <div className="flex-1 pl-10">
            <Map
              center={mapCenter}
              competitorLocations={competitorLocations}
              facilityLocation={facilityLocation}
            />
          </div>
        </div>
      </div>
    )
  }

  return <Dashboard>{renderTable()}</Dashboard>
}

export default Competitors