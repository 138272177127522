// src/App.js
import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import StreetRates from './pages/StreetRates'
import ExistingCustomers from './pages/ExistingCustomers'
import Competitors from './pages/Competitors'
import Settings from './pages/Settings'
import SignIn from './pages/SignIn'
import ResetPassword from './pages/ResetPassword'
import Loading from './pages/Loading';
import { AuthContext } from './context/authContext'
import './styles/index.css'

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext)

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />
  }

  return children
}

const App = () => {
  
  return (
    <Routes>
      <Route path="/sign-in" exact element={<SignIn />} />
      <Route path="/reset-password" exact element={<ResetPassword />} />
      <Route path="/loading" exact element={<Loading />} />
      <Route
        path="/street-rates"
        element={
          <ProtectedRoute>
            <StreetRates />
          </ProtectedRoute>
        }
      />
      <Route
        path="/existing-customer-rate-increases"
        element={
          <ProtectedRoute>
            <ExistingCustomers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/competitors"
        element={
          <ProtectedRoute>
            <Competitors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/competitors/:id"
        element={
          <ProtectedRoute>
            <Competitors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/:id"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate replace to="/street-rates" />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default App
