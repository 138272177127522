import React, { useState, useEffect } from 'react'
import Modal from '../components/atom/Modal'
import moment from 'moment'
import Dashboard from '../components/Dashboard'
import SearchInput from '../components/atom/SearchInput'
import StreetRatesTable from '../components/StreetRatesTable'
import Pagination from '../components/atom/Pagination'
import apiClient from '../utils/apiClient'

const StreetRates = () => {
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [debouncedTerm, setDebouncedTerm] = useState(search)
  const [updatedFacilitiesCount, setUpdatedFacilitiesCount] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [latestPublishedDate, setLatestPublishedDate] = useState('')
  const [sort, setSort] = useState('facility_name')
  const [orderby, setOrderby] = useState('asc')
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      let api = `/street_rates`

      let queries = []
      if (search) queries.push(`search=${search}`)
      if (sort) queries.push(`sort=${sort}`)
      if (orderby) queries.push(`orderby=${orderby}`)
      if (currentPage) queries.push(`page=${currentPage}`)
      if (queries.length) api += '?' + queries.join('&')

      let response = await apiClient.get(api)
      const { result } = await response.data
      setData(result)
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search)
      setCurrentPage(1)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [search])

  useEffect(() => {
    fetchData()
  }, [debouncedTerm, sort, orderby, currentPage])

  const handleChange = (e) => {
    const value = e.target.value
    setSearch(value)
  }

  const handlePagination = (page) => {
    setCurrentPage(page)
  }

  const handleFacilitiesChanged = (numOfUpdatedFacilities) => {
    setUpdatedFacilitiesCount(numOfUpdatedFacilities)
  }

  const handlePublishNewRates = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const confirmPublish = () => {
    apiClient.post('/street_rates/submit').then(function (response) {
      setLatestPublishedDate(moment(new Date()).format('MM/DD/YYYY, hh:mm A'));
      setModalIsOpen(false);

      setTimeout(() => {
        window.confirm('Rates successfully published');
      }, 300);
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen(false);
    })
  }

  const handleUpdate = async (facility) => {
    try {
      await apiClient.post('/street_rates/save', {
        facility_id: facility.facility_id,
        units: facility.units_statistics,
      })
      fetchData()
    } catch (e) {
    }
  }

  const handleSort = (columnName, isASC) => {
    if (columnName === sort) {
      setOrderby(isASC ? 'asc' : 'desc')
    } else {
      setSort(columnName)
      setOrderby('asc')
    }
  }

  const refreshData = () => {
    apiClient.post('/street_rates/run-python').then(function (response) {
      alert("Done!");
      fetchData();
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen(false);
    })
  }

  const tableHeader = () => {
    return (
      <>
        <div className="p-4 flex justify-between">
          <SearchInput placeholder={'Search or filter...'} value={search} onChange={handleChange} />
          <div>
            <button
              className="px-8 mb-2 text-red-500 font-normal text-base flex items-center gap-4"
              onClick={refreshData}
            >
              <span className="material-symbols-rounded">
                refresh
              </span>
              Refresh Model
            </button>
            <button
              className="px-8 rounded rounded-lg bg-green-600 text-white"
              onClick={handlePublishNewRates}
            >
              Publish New Rates
            </button>
            <Modal
              isOpen={modalIsOpen}
            >
              <h2>Are you sure you want to publish the new street rates?</h2>
              <div class="action mt-4 text-center">
                <button
                  type="button"
                  className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                  onClick={closeModal}
                >
                  NO
                </button>
                <button
                  type="button"
                  className="border border-radius-lg border-primary uppercase text-primary text-sm"
                  onClick={confirmPublish}
                >
                  YES
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm text-slate-500 px-4">Last Updated: {latestPublishedDate}</div>
          <span className="text-sm text-slate-500 px-4">
            {updatedFacilitiesCount > 0
              ? `${updatedFacilitiesCount} ${updatedFacilitiesCount === 1 ? 'Facility' : 'Facilities'
              } Edited`
              : ''}
          </span>
        </div>
      </>
    )
  }

  const renderTable = () => {
    return (
      <>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-6 pt-6 bg-white">
          {tableHeader()}
          {loading ? <div className="flex w-full h-4/5 items-center justify-center">Loading ...</div> :
            <StreetRatesTable
              data={data}
              sortColumn={sort}
              sortDirection={orderby}
              handleChange={handleFacilitiesChanged}
              handleUpdate={handleUpdate}
              onSortChanged={handleSort}
            />}
        </div>
        <Pagination
          totalPages={Math.ceil(data.length / 10)}
          page={currentPage}
          handlePagination={handlePagination}
        />
      </>
    )
  }

  return <Dashboard>{renderTable()}</Dashboard>
}

export default StreetRates
