export function adjustDayOfMonth(selectedDay, month, year = new Date().getFullYear()) {
  // Create a Date object for the 1st of the next month
  const nextMonth = new Date(year, month, 1);
  
  // Subtract one day to get the last day of the current month
  const lastDayOfMonth = new Date(nextMonth - 1).getDate();
  
  // Return the smaller of the selected day and the last day of the month
  return Math.min(selectedDay, lastDayOfMonth);
}
